import axios from 'axios'
import { Message } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

// 创建 axios 实例
const service = axios.create({
  // baseURL: 'http://wdadmin.46470.com', // 使用代理配置中的 /api 前缀
  // baseURL: 'http://gw.688110.com', // 使用代理配置中的 /api 前缀
  baseURL: process.env.VUE_APP_API_DOMAIN, // 使用代理配置中的 /api 前缀
  // baseURL: 'https://gwapi.digital-shield.com', // 使用代理配置中的 /api 前缀
  timeout: 15000, // 请求超时时间
  headers: {
    'Content-Type': 'application/json;charset=utf-8'
  }
})

// 请求拦截器
service.interceptors.request.use(
  config => {
    // 从本地存储获取 token
    const token = localStorage.getItem('token')
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }

    // 添加设备信息
    config.headers['mac'] = process.env.VUE_APP_MAC
    config.headers['address'] = process.env.VUE_APP_ADDRESS

    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  response => {
    const res = response.data

    if (res.code === 200 || res.code === 1) {
      return res.data
    }
     else {
      Message({
        message: res.message || res.msg || '请求失败',
        type: 'error',
        duration: 3000
      })
      // return Promise.reject(new Error(res.message || res.msg || '请求失败'))
    }
  },
  // error => {
  //   // const message = error.response?.data?.message ||
  //   //   error.response?.data?.msg ||
  //   //   error.message ||
  //   //   '服务器错误'
  //   // Message({
  //   //   message: message,
  //   //   type: 'error',
  //   //   duration: 3000
  //   // })
  //   // return Promise.reject(error)
  // }
)

export default service 