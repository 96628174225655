<template>
  <div class="nav-container">
    <!-- 导航栏 -->
    <div class="nav-bar">
      <div class="logo">
        <img
          src="https://dspro999.s3.ap-east-1.amazonaws.com/gov/assets/images/name.png"
          alt="Digital Shield"
        />
      </div>
      <div class="nav-right">
        <!-- <div class="message-icon">
          <img src="https://dspro999.s3.ap-east-1.amazonaws.com/gov/assets/images/name.png" alt="message" />
        </div>-->
        <div class="menu-icon" @click="toggleMenu">
          <img
            src="https://dspro999.s3.ap-east-1.amazonaws.com/gov/assets/mobile/nav.png"
            alt="menu"
          />
        </div>
      </div>
    </div>

    <!-- 弹出菜单 -->
    <transition name="slide">
      <div v-if="showMenu" class="menu-overlay">
        <div class="menu-content">
          <div class="close-btn" @click="toggleMenu">
            <img
              src="https://dspro999.s3.ap-east-1.amazonaws.com/gov/assets/mobile/close.png"
              alt="close"
            />
          </div>
          <div class="menu-items">
            <div class="menu-item" @click="handleSelect(5)">
              <span>{{$t('infoDatasTitle.top_titele_one')}}</span>
              <img
                src="https://dspro999.s3.ap-east-1.amazonaws.com/gov/assets/images/rightArrow.png"
                alt="arrow"
              />
            </div>
            <div class="menu-item" @click="handleSelect(1)">
              <span>{{$t('infoDatasTitle.top_titele_two')}}</span>
              <img
                src="https://dspro999.s3.ap-east-1.amazonaws.com/gov/assets/images/rightArrow.png"
                alt="arrow"
              />
            </div>
            <div class="menu-item" @click="handleSelect(2)">
              <span>{{$t('infoDatasTitle.top_titele_three')}}</span>
              <img
                src="https://dspro999.s3.ap-east-1.amazonaws.com/gov/assets/images/rightArrow.png"
                alt="arrow"
              />
            </div>
            <div class="menu-item" @click="handleSelect(3)">
              <span>{{$t('infoDatasTitle.top_titele_four')}}</span>
              <img
                src="https://dspro999.s3.ap-east-1.amazonaws.com/gov/assets/images/rightArrow.png"
                alt="arrow"
              />
            </div>
            <div class="menu-item" @click="handleSelect(4)">
              <span>{{$t("infoDatasTitle.top_titele_five")}}</span>
              <img
                src="https://dspro999.s3.ap-east-1.amazonaws.com/gov/assets/images/rightArrow.png"
                alt="arrow"
              />
            </div>
            <div class="menu-item language-toggle" @click="toggleLanguage">
              <span>{{$t("infoDatasTitle.top_titele_six")}}</span>
              <img
                src="https://dspro999.s3.ap-east-1.amazonaws.com/gov/assets/images/rightArrow.png"
                alt="arrow"
                class="arrow-down"
                :class="{ 'rotate': showLanguages }"
              />
            </div>
          </div>
          <transition name="collapse">
            <div class="lang-options" v-show="showLanguages">
              <div
                v-for="(lang, index) in languages"
                :key="index"
                class="lang-item"
                :class="{ 'active': currentLanguages === lang }"
                @click="selectLanguage(lang)"
              >{{ lang.name }}</div>
            </div>
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { languageList, topIndex } from "@/api/payment";
import { mapState } from "vuex";
export default {
  name: "NavBar",
  data() {
    return {
      showMenu: false,
      showLanguages: false,
      // currentLang: "簡體中文",
      // languages: ["簡體中文", "English", "日本語", "繁體中文", "Français"],
      infoDatas: {},
      cartVisible: false,
      currentLanguage: localStorage.getItem("languageName") || "繁體中文",
      languages: [],
      code: localStorage.getItem("language") || "tw",
      activeIndex: localStorage.getItem("activeIndex") || "1",
      activeIndexs: "1",
      langIcon: "",
      currentLanguages: {}
    };
  },
  created() {
    this.activeIndex = localStorage.getItem("activeIndex") || "1";
    const savedCode = localStorage.getItem("language") || "tw";
    if (savedCode !== this.$i18n.locale) {
      this.$i18n.locale = savedCode;
    }
  },
  computed: {
    ...mapState("language", ["currentLanguage"])
  },
  watch: {
    "$i18n.locale": {
      immediate: true,
      deep: true,
      handler(newLocale) {
        // 如果 languages 为空，等待数据加载完成
        if (this.languages.length === 0) {
          this.languageListData().then(() => {
            this.updateLanguageConfig(newLocale);
          });
        } else {
          this.updateLanguageConfig(newLocale);
        }
      }
    }
  },
  mounted() {
    this.languageListData();
    this.topIndexData();
  },
  methods: {
    updateLanguageConfig(newLocale) {
      const currentLang = this.languages.find(lang => {
        return lang.code === newLocale;
      });

      if (currentLang) {
        this.code = newLocale;
        this.currentLanguage = currentLang.name;
        this.langIcon = currentLang.icon;

        localStorage.setItem("language", newLocale);
        localStorage.setItem("languageName", currentLang.name);
        localStorage.setItem("langIcon", currentLang.icon);
      }

      this.$store.dispatch("languages/updateLanguage", {
        code: newLocale,
        name: this.currentLanguage
      });
    },
    languageListData() {
      return languageList()
        .then(res => {
          this.languages = res;
          const currentCode = localStorage.getItem("language") || "tw";
          const currentLang = res.find(lang => lang.code === currentCode);
          if (currentLang) {
            this.langIcon = currentLang.icon;
            localStorage.setItem("langIcon", currentLang.icon);
          } else {
            this.langIcon = res[0].icon;
            localStorage.setItem("langIcon", res[0].icon);
          }
        })
        .catch(err => {
          console.error("获取语言列表失败:", err);
        });
    },
    topIndexData() {
      topIndex({ language: this.code }).then(res => {
        this.infoDatas = res;
      });
    },
    // languageListData() {
    //   languageList({ language: this.code }).then(res => {
    //     this.languages = res;
    //     this.langIcon = res[0].icon;
    //   });
    // },
    handleSelect(key) {
      this.activeIndexs = key;

      localStorage.setItem("activeIndex", key);
      if (key == 2 && this.$route.path !== "/down") {
        this.$router.push("/down");
      }
      if (key == 4 && this.$route.path !== "/about") {
        this.$router.push("/about");
      }
      if (key == 3 && this.$route.path !== "/guide") {
        this.$router.push("/guide");
      }
      if (key == 5 && this.$route.path !== "/") {
        this.$router.push("/");
      }
      if (key == 1 && this.$route.path !== "/product") {
        this.$router.push("/product");
      }
    },
    toggleLanguage() {
      this.showLanguages = !this.showLanguages;
    },
    selectLanguage(lang) {
      this.showMenu = false;
      this.code = lang.code;
      localStorage.setItem("language", this.code);
      localStorage.setItem("languageName", lang.name);


      // 更新 i18n 和 store
      this.$i18n.locale = lang.code;
      this.$store.dispatch("languages/updateLanguage", {
        code: lang.code,
        name: lang.name
      });
      this.$emit("languageChange", this.code);

      // this.topIndexData();
      // this.$store.dispatch("language/updateLanguage", {
      //   code: lang.code,
      //   name: lang.name
      // });
      // 这里可以添加切换语言的逻辑
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
      // if (this.showMenu) {
      //   document.body.style.overflow = "hidden";
      // } else {
      //   document.body.style.overflow = "";
      // }
    },
    goToHome() {
      if (this.$route.path !== "/") {
        this.$router.push("/");
        localStorage.setItem("activeIndex", "1");
      }
    },
    oneClickPro() {
      this.$router.push("/cart");
    },
    goToRegister() {
      this.$router.push("/register");
    },
    goToProducts(type) {
      this.$router.push(
        { path: "/products" },
        () => {},
        () => {}
      );
      this.$store.commit("down/UPDATE_DATA", type);
    },
    oneKeyClassic() {
      this.$router.push("/orderIndex");
    },
    handleLanguageChange(code) {
      this.currentLanguages = this.languages.find(lang => lang.code === code);
      this.currentLanguage = this.currentLanguages.name;
      this.langIcon = this.currentLanguages.icon;
      this.code = code;
      localStorage.setItem("language", code);
      localStorage.setItem("languageName", this.currentLanguages.name);

      this.$emit("languageChange", code);
      this.topIndexData();
      // 通过 EventBus 发送消息
      this.$store.dispatch("language/updateLanguage", {
        code: code,
        name: this.currentLanguages.name
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.nav-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.nav-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  background: rgba(10, 13, 13, 0.8);
  backdrop-filter: blur(8px);

  .logo {
    height: 0.5333rem;
    line-height: 11px;
    img {
      height: 100%;
    }
  }

  .nav-right {
    display: flex;
    align-items: center;
    gap: 1.5rem;

    .message-icon,
    .menu-icon {
      cursor: pointer;
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
}

.menu-overlay {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);

  .menu-content {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    background: #0a0d0d;
    padding: 2rem 0;

    .close-btn {
      position: absolute;
      top: 3px;
      right: 3px;
      padding: 18.75px;
      width: 24px;
      height: 24px;
      img {
        width: 100%;
        height: 100%;
      }
      cursor: pointer;
    }

    .menu-items {
      margin-top: 15px;

      .menu-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16.0013px 15px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        .arrow-down {
          transition: transform 0.3s ease;
          &.rotate {
            transform: rotate(90deg);
          }
        }
        span {
          font-family: MiSans TC, MiSans TC;
          font-weight: 400;
          font-size: 0.3733rem;
          color: #cccccc;
        }

        img {
          width: 0.7rem;
          height: 0.7rem;
          opacity: 0.7;
        }

        // &.language-toggle {
        //   margin-top: 75px;
        // }
      }
    }

    .lang-options {
      background: rgba(255, 255, 255, 0.05);
      // overflow: hidden;

      .lang-item {
        font-family: MiSans TC, MiSans TC;
        font-weight: 400;
        font-size: 12px;
        color: #8a8a8a;
        line-height: 24px;
        padding: 0.2667rem 0.4rem;
        cursor: pointer;

        &.active {
          color: #fff;
        }

        &:hover {
          background: rgba(255, 255, 255, 0.1);
        }
      }
    }
  }
}
.collapse-enter-active,
.collapse-leave-active {
  transition: all 0.3s ease;
  max-height: 300px;
}

.collapse-enter,
.collapse-leave-to {
  max-height: 0;
  opacity: 0;
}
.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
  opacity: 0;
}
</style>