<template>
  <div class="nav-container">
    <!-- <div class="nav-bar">
      <div class="logo">
        <img src="https://dspro999.s3.ap-east-1.amazonaws.com/gov/assets/images/name.png" alt="Digital Shield" />
      </div>
      <div class="nav-right">
        <div class="menu-icon" @click="toggleMenu">
          <img src="https://dspro999.s3.ap-east-1.amazonaws.com/gov/assets/mobile/nav.png" alt="menu" />
        </div>
      </div>
    </div>-->

    <transition name="slide">
      <div class="menu-overlay">
        <div class="menu-content">
          <!-- <div class="close-btn" @click="toggleMenu">
            <img src="https://dspro999.s3.ap-east-1.amazonaws.com/gov/assets/images/name.png" alt="close" />
          </div>-->
          <div class="menu-items">
            <!-- 产品服务 -->
            <div class="menu-item" @click="toggleSubmenu('products')">
              <span>{{ $t('infoDataFoter.content1') }}</span>
              <img
                src="https://dspro999.s3.ap-east-1.amazonaws.com/gov/assets/images/rightArrow.png"
                alt="arrow"
                :class="{ 'rotate': activeMenu === 'products' }"
              />
            </div>
            <transition name="collapse">
              <div class="submenu" v-show="activeMenu === 'products'">
                <div class="submenu-item" @click="product">{{ $t('infoDataFoter.content2') }}</div>
                <div class="submenu-item" @click="down">{{ $t('infoDataFoter.content3') }}</div>
                <div class="submenu-item" @click="current">{{ $t('infoDataFoter.content61') }}</div>
                <div class="submenu-item" @click="deale">{{ $t('infoDataFoter.content19') }}</div>
                <!-- <div>{{ infoDataFoter.content4 }}</div>
                  <div>{{ infoDataFoter.content7 }}</div>
                <div>{{ infoDataFoter.content8 }}</div>-->
              </div>
            </transition>

            <!-- 政策法规 -->
            <div class="menu-item" @click="toggleSubmenu('policy')">
              <span>{{ $t('infoDataFoter.content5') }}</span>
              <img
                src="https://dspro999.s3.ap-east-1.amazonaws.com/gov/assets/images/rightArrow.png"
                alt="arrow"
                :class="{ 'rotate': activeMenu === 'policy' }"
              />
            </div>
            <transition name="collapse">
              <div class="submenu" v-show="activeMenu === 'policy'">
                <div class="submenu-item" @click="regula(2)">{{ $t('infoDataFoter.content8') }}</div>
                <div class="submenu-item" @click="regula(3)">{{ $t('infoDataFoter.content11') }}</div>
                <div class="submenu-item" @click="regula(1)">{{ $t('infoDataFoter.content12') }}</div>
              </div>
            </transition>

            <!-- 联系我们 -->
            <div class="menu-item" @click="toggleSubmenu('contact')">
              <span>{{ $t('infoDataFoter.content6') }}</span>
              <img
                src="https://dspro999.s3.ap-east-1.amazonaws.com/gov/assets/images/rightArrow.png"
                alt="arrow"
                :class="{ 'rotate': activeMenu === 'contact' }"
              />
            </div>
            <transition name="collapse">
              <div class="submenu" v-show="activeMenu === 'contact'">
                <div class="submenu-item" @click="kf">{{ $t('infoDataFoter.content10') }}</div>
                <div class="submenu-item" @click="kf">{{ $t('infoDataFoter.content13') }}</div>
                <div class="submenu-item" @click="kf">{{ $t('infoDataFoter.content16') }}</div>
              </div>
            </transition>
            <!-- 关于我们 -->
            <div class="menu-item" @click="toggleSubmenu('about')">
              <span>{{ $t('infoDataFoter.content7') }}</span>
              <img
                src="https://dspro999.s3.ap-east-1.amazonaws.com/gov/assets/images/rightArrow.png"
                alt="arrow"
                :class="{ 'rotate': activeMenu === 'about' }"
              />
            </div>
            <transition name="collapse">
              <div class="submenu" v-show="activeMenu === 'about'">
                <div class="submenu-item" @click="about">{{ $t('infoDataFoter.content9') }}</div>
                <div class="submenu-item" @click="mediaClick">{{ $t('infoDataFoter.content16') }}</div>
              </div>
            </transition>
            <!-- 语言切换 -->
            <!-- <div class="menu-item" @click="toggleSubmenu('language')">
              <span>切換語言</span>
              <img
                src="https://dspro999.s3.ap-east-1.amazonaws.com/gov/assets/images/rightArrow.png"
                alt="arrow"
                :class="{ 'rotate': activeMenu === 'language' }"
              />
            </div>
            <transition name="collapse">
              <div class="submenu" v-show="activeMenu === 'language'">
                <div
                  v-for="(lang, index) in languages"
                  :key="index"
                  class="submenu-item"
                  :class="{ 'active': currentLang === lang }"
                  @click="selectLanguage(lang)"
                >{{ lang }}</div>
              </div>
            </transition>-->
          </div>
          <div class="footerLeft">
            <div class="footerLeftTop">
              <img src="https://dspro999.s3.ap-east-1.amazonaws.com/gov/assets/images/name.png" alt />
            </div>
            <div class="footerLeftCenter">
              <!-- <div class="css-12rl58b"> -->
              <div class="footerLeftCenterone">
                <!-- <div class="text" >Digital Shield</div> -->
                <div class="imgs">
                  <div @click="tt" style="cursor: pointer;">
                    <img
                      style="width:32px;height:32px;"
                      src="https://dspro999.s3.ap-east-1.amazonaws.com/gov/assets/images/footer11.png"
                      alt
                    />
                  </div>
                  <div @click="ins" style="cursor: pointer;">
                    <img
                      style="width:32px;height:32px;"
                      src="https://dspro999.s3.ap-east-1.amazonaws.com/gov/assets/images/footer12.png"
                      alt
                    />
                  </div>
                  <div @click="tg" style="cursor: pointer;">
                    <img
                      style="width:32px;height:32px;"
                      src="https://dspro999.s3.ap-east-1.amazonaws.com/gov/assets/images/footer13.png"
                      alt
                    />
                  </div>
                  <div @click="fc" style="cursor: pointer;">
                    <img
                      style="width:32px;height:32px;"
                      src="https://dspro999.s3.ap-east-1.amazonaws.com/gov/assets/images/footer14.png"
                      alt
                    />
                  </div>
                  <div @click="ty" style="cursor: pointer;">
                    <img
                      style="width:32px;height:32px;"
                      src="https://dspro999.s3.ap-east-1.amazonaws.com/gov/assets/images/footer15.png"
                      alt
                    />
                  </div>
                </div>
              </div>

              <!-- </div> -->
            </div>
          </div>
          <div class="bine">{{ bine }}</div>
        </div>
      </div>
    </transition>
  </div>
</template>
  
  <script>
import { languageAbout, getWebsiteTitle } from "@/api/payment";
export default {
  name: "NavBar",
  data() {
    return {
      showMenu: false,
      activeMenu: "",
      currentLang: "簡體中文",
      // languages: ["簡體中文", "English", "日本語", "繁體中文", "Français"],
      infoDataFoter: {},
      code: this.$store.state.language.currentLanguage,
      bine: ""
    };
  },
  created() {
    // 监听语言变化
    this.$store.watch(
      state => state.language.currentLanguage,
      newCode => {
        this.code = newCode;
        this.languageAboutData();
      }
    );
  },
  mounted() {
    this.languageAboutData();
    this.getWebsiteTitleData();
  },
  methods: {
    deale(){
      this.$router.push("/dealer");
    },
    getWebsiteTitleData() {
      getWebsiteTitle().then(res => {
        this.bine = res.beian;
      });
    },
    kf() {
      if (this.$route.path != "/about") {
        this.$router.push("/about").then(() => {
          // 等待页面加载和DOM更新完成
          setTimeout(() => {
            const element = document.querySelector(".email");
            if (element) {
              element.scrollIntoView({
                behavior: "smooth", // 平滑滚动
                block: "center" // 将元素滚动到视窗中间
              });
            }
          }, 500); // 给一个适当的延时确保DOM已更新
        });
      }
    },
    regula(type) {
      this.$router.push({
        path: "/regulations",
        query: {
          type: type
        }
      });
    },
    tt() {
      window.location.href = "https://x.com/DigitShield_HQ";
    },
    ins() {
      window.location.href = "https://www.instagram.com/digital.shield.wallet/";
    },
    tg() {
      window.location.href = " https://t.me/digitshield";
    },
    fc() {
      window.location.href =
        " https://www.facebook.com/profile.php?id=61571313262914";
    },
    ty() {
      window.location.href = "https://www.youtube.com/@DigitalShield-s6j";
    },
    // toggleMenu() {
    //   this.showMenu = !this.showMenu;
    //   if (this.showMenu) {
    //     document.body.style.overflow = "hidden";
    //   } else {
    //     document.body.style.overflow = "";
    //     this.activeMenu = ""; // 关闭菜单时重置所有展开状态
    //   }
    // },
    toggleSubmenu(menu) {
      this.activeMenu = this.activeMenu === menu ? "" : menu;
    },
    selectLanguage(lang) {
      this.currentLang = lang;
      this.activeMenu = "";
    },
    about() {
      if (this.$route.path !== "/about") {
        this.$router.push("/about");
      }
    },
    down() {
      if (this.$route.path !== "/down") {
        this.$router.push("/down");
      }
    },
    product() {
      if (this.$route.path !== "/product") {
        this.$router.push("/product");
      }
    },
    current() {
      if (this.$route.path !== "/currency") {
        this.$router.push("/currency");
      }
    },
    mediaClick() {
      if (this.$route.path !== "/media") {
        this.$router.push("/media");
      }
    },
    languageAboutData() {
      this.infoDataFoter = [];

      languageAbout({
        language: this.code
      }).then(res => {
        this.infoDataFoter = res;
      });
    }
  }
};
</script>
  
  <style lang="scss" scoped>
.nav-container {
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  width: 100%;
  z-index: 1000;
}

.nav-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  background: rgba(10, 13, 13, 0.8);
  backdrop-filter: blur(8px);

  .logo {
    height: 1rem;
    img {
      height: 100%;
    }
  }

  .menu-icon {
    cursor: pointer;
    img {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}

.menu-overlay {
  //   position: fixed;
  //   top: 0;
  //   right: 0;
  width: 100%;
  //   height: 100vh;
  //   background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  .footerLeft {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    // width: 100%;
    padding: 10px 15px;
    // background: #0a0d0d;

    .footerLeftTop {
      margin-bottom: 10px;
      img {
        height: 0.5333rem;
        line-height: 11px;
        object-fit: contain;
      }
    }

    .footerLeftCenter {
      .footerLeftCenterone {
        .text {
          font-family: Archivo, Archivo;
          font-weight: bold;
          font-size: 1.25rem;
          color: #ccced3;
          margin-bottom: 1rem;
        }

        .imgs {
          display: flex;
          align-items: center;
          gap: 10px;
          // padding-bottom: 1.25rem;

          div {
            cursor: pointer;

            img {
              width: 1.5rem;
              height: 1.0625rem;
              object-fit: contain;
              transition: opacity 0.3s ease;

              &:hover {
                opacity: 0.8;
              }
            }
          }
        }
      }
    }
  }
  .bine {
    font-family: MiSans TC, MiSans TC;
    font-weight: 400;
    font-size: 10px;
    color: #9a9ea7;
    line-height: 24px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    padding: 10px;
  }
  .menu-content {
    // position: fixed;
    // top: 0;
    // right: 0;
    width: 100%;
    height: 100%;
    background: #0a0d0d;
    // padding: 2rem 0;

    .close-btn {
      position: absolute;
      top: 1rem;
      right: 1rem;
      padding: 0.5rem;
      cursor: pointer;
      img {
        width: 1.2rem;
        height: 1.2rem;
      }
    }

    .menu-items {
      margin-top: 3rem;

      .menu-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px 15px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        // cursor: pointer;

        span {
          font-family: MiSans TC, MiSans TC;
          font-weight: 400;
          font-size: 14px;
          color: #cccccc;
          line-height: 16px;
        }

        img {
          width: 16px;
          height: 16px;
          opacity: 0.7;
          transition: transform 0.3s ease;

          &.rotate {
            transform: rotate(90deg);
          }
        }
      }

      .submenu {
        background: rgba(255, 255, 255, 0.05);
        overflow: hidden;

        .submenu-item {
          font-family: MiSans TC, MiSans TC;
          font-weight: 400;
          font-size: 12px;
          color: #8a8a8a;
          padding: 16px 15px;
          &:hover,
          &.active {
            color: #fff;
            background: rgba(255, 255, 255, 0.1);
          }
        }
      }
    }
  }
}

.collapse-enter-active,
.collapse-leave-active {
  transition: all 0.3s ease;
  max-height: 300px;
}

.collapse-enter,
.collapse-leave-to {
  max-height: 0;
  opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
  opacity: 0;
}
</style>