<template>
  <div>
    <div class="question">
      <div class="footer">
        <div class="cocntent">
          <div class="footerLeft">
            <div class="footerLeftTop">
              <img
                style="width: 12.375rem;height:2rem;"
                src="https://dspro999.s3.ap-east-1.amazonaws.com/gov/assets/images/name.png"
                alt
              />
            </div>
            <div class="footerLeftCenter">
              <!-- <div class="css-12rl58b"> -->
              <div class="footerLeftCenterone">
                <!-- <div class="text" >Digital Shield</div> -->
                <div
                  class="imgs"
                  style="display: flex;align-items:center;gap: 1.875rem;padding-bottom: 1.25rem;"
                >
                  <div @click="tt" style="cursor: pointer;">
                    <img
                      style="width:1.5rem;height:1.5625rem;"
                      src="https://dspro999.s3.ap-east-1.amazonaws.com/gov/assets/images/footer11.png"
                      alt
                    />
                  </div>
                  <div @click="ins" style="cursor: pointer;">
                    <img
                      style="width:1.5625rem;height:1.5625rem;"
                      src="https://dspro999.s3.ap-east-1.amazonaws.com/gov/assets/images/footer12.png"
                      alt
                    />
                  </div>
                  <div @click="tg" style="cursor: pointer;">
                    <img
                      style="width:1.5rem;height:1.25rem;"
                      src="https://dspro999.s3.ap-east-1.amazonaws.com/gov/assets/images/footer13.png"
                      alt
                    />
                  </div>
                  <div @click="fc" style="cursor: pointer;">
                    <img
                      style="width:1.5rem;height:1.5rem;"
                      src="https://dspro999.s3.ap-east-1.amazonaws.com/gov/assets/images/footer14.png"
                      alt
                    />
                  </div>
                  <div @click="ty" style="cursor: pointer;">
                    <img
                      style="width:1.5rem;height:1.0625rem;"
                      src="https://dspro999.s3.ap-east-1.amazonaws.com/gov/assets/images/footer15.png"
                      alt
                    />
                  </div>
                </div>
              </div>

              <!-- </div> -->
            </div>
          </div>
          <div class="footerRight">
            <div class="right">
              <div class="rightItem">
                <span class="rightItemTop">{{$t('infoDataFoter.content1')}}</span>
                <div class="rightItemBottom">
                  <div @click="product">{{ $t('infoDataFoter.content2') }}</div>
                  <div @click="down">{{ $t('infoDataFoter.content3') }}</div>
                  <!-- <div>{{ $t('infoDataFoter.content4') }}</div>
                  <div>{{ $t('infoDataFoter.content7') }}</div>
                  <div>{{ $t('infoDataFoter.content8') }}</div>-->
                  <div @click="current">{{ $t('infoDataFoter.content61') }}</div>
                  <div @click="deale">{{ $t('infoDataFoter.content19') }}</div>
                </div>
              </div>
            </div>
            <div class="right">
              <div class="rightItem">
                <span class="rightItemTop">{{ $t('infoDataFoter.content5') }}</span>
                <div class="rightItemBottom">
                  <div @click="regula(2)">{{ $t('infoDataFoter.content8') }}</div>
                  <div @click="regula(3)">{{ $t('infoDataFoter.content11') }}</div>
                  <div @click="regula(1)">{{ $t('infoDataFoter.content12') }}</div>
                </div>
              </div>
            </div>
            <div class="right">
              <div class="rightItem">
                <span class="rightItemTop">{{ $t('infoDataFoter.content6') }}</span>
                <div class="rightItemBottom">
                  <!-- <div @click="kf">{{ infoDataFoter.content10 }}</div> -->
                  <div @click="kf">{{ $t('infoDataFoter.content13') }}</div>
                  <div @click="kf">{{ $t('infoDataFoter.content16') }}</div>
                </div>
              </div>
            </div>
            <div class="right">
              <div class="rightItem">
                <span class="rightItemTop">{{ $t('infoDataFoter.content7') }}</span>
                <div class="rightItemBottom">
                  <div @click="about">{{ $t('infoDataFoter.content9') }}</div>
                  <div @click="mediaClick">{{ $t('infoDataFoter.content15') }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="line"></div>
      <div class="footerText">{{ bine }}</div>
    </div>
  </div>
</template>
<script>
import { getWebsiteTitle } from "@/api/payment";
export default {
  name: "footerIndex",
  data() {
    return {
      // infoDataFoter: {},
      code: this.$store.state.language.currentLanguage,
      bine: ""
    };
  },
  // created() {
  //   // 监听语言变化
  //   this.$store.watch(
  //     state => state.language.currentLanguage,
  //     newCode => {
  //       this.code = newCode;
  //       this.languageAboutData();
  //     }
  //   );
  // },
  mounted() {
    // this.languageAboutData();
    this.getWebsiteTitleData();
  },
  methods: {
    deale(){
      this.$router.push("/dealer");
    },
    getWebsiteTitleData() {
      getWebsiteTitle().then(res => {
        this.bine = res.beian;
      });
    },
    kf() {
      if (this.$route.path != "/about") {
        localStorage.setItem("activeIndex", "5");
        this.$router.push("/about").then(() => {
          this.$nextTick(() => {
            const element = document.querySelector(".email");
            if (element) {
              element.scrollIntoView({
                behavior: "smooth",
                block: "center"
              });
            }
          });
        });
      }
    },
    regula(type) {
      localStorage.setItem("activeIndex", "0");
      this.$router.push({
        path: "/regulations",
        query: {
          type: type
        }
      });
    },
    tt() {
      window.location.href = "https://x.com/DigitShield_HQ";
    },
    ins() {
      window.location.href = "https://www.instagram.com/digital.shield.wallet/";
    },
    tg() {
      window.location.href = " https://t.me/digitshield";
    },
    fc() {
      window.location.href =
        " https://www.facebook.com/profile.php?id=61571313262914";
    },
    ty() {
      window.location.href = "https://www.youtube.com/@DigitalShield-s6j";
    },
    about() {
      if (this.$route.path !== "/about") {
        localStorage.setItem("activeIndex", "5");
        this.$router.push("/about");
      }
    },
    down() {
      if (this.$route.path !== "/down") {
        localStorage.setItem("activeIndex", "3");
        this.$router.push("/down");
      }
    },
    product() {
      if (this.$route.path !== "/product") {
        localStorage.setItem("activeIndex", "2");
        this.$router.push("/product");
      }
    },
    current() {
      if (this.$route.path !== "/currency") {
        localStorage.setItem("activeIndex", "0");
        this.$router.push("/currency");
      }
    },
    mediaClick() {
      if (this.$route.path !== "/media") {
        localStorage.setItem("activeIndex", "0");
        this.$router.push("/media");
      }
    }
    // languageAboutData() {
    //   this.infoDataFoter = [];

    //   languageAbout({
    //     language: this.code
    //   }).then(res => {
    //     this.infoDataFoter = res;
    //   });
    // }
  }
};
</script>
<style lang="scss" scoped>
.question {
  width: 100%;
  height: 100%;
  background-color: #0a0d0d;

  .content {
    display: flex;
    align-items: flex-start;
    // max-width: 82.5rem;
    // margin: 0 auto;
    gap: 3.125rem;
    padding-top: 7.25rem;
    .left {
      width: 50%;
      // height: 100%;
      .leftItem {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1.25rem;
        padding: 1.25rem 0;
        border-bottom: 0.0625rem solid rgba(217, 217, 217, 0.1);
        .text {
          font-family: Archivo, Archivo;
          font-weight: 400;
          font-size: 1.5rem;
          color: #818691;
          font-style: normal;
          text-transform: none;
        }
        .img {
          width: 2rem;
          height: 2rem;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .leftItemMore {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1.25rem;
        padding: 1.25rem 0;
        border-bottom: 0.0625rem solid rgba(217, 217, 217, 0.1);
        .text {
          font-family: Archivo, Archivo;
          font-weight: 400;
          font-size: 1.5rem;
          color: #818691;
          font-style: normal;
          text-transform: none;
        }
        .img {
          width: 2rem;
          height: 2rem;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .text:hover {
          color: #ffffff;
        }
      }
    }
    .right {
      width: 50%;
      cursor: pointer;
      .textOne {
        font-family: AR One Sans, AR One Sans;
        font-weight: 400;
        font-size: 2.75rem;
        color: #afb3ca;
        letter-spacing: 0.0625rem;
        font-style: normal;
        text-transform: none;
      }
      .textTwo {
        font-family: AR One Sans, AR One Sans;
        font-weight: 400;
        font-size: 1rem;
        color: #afb3ca;
        font-style: normal;
        text-transform: none;
      }
      .textThree {
        margin-top: 0.625rem;
        width: 100%;
        height: 7.5rem;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .footer {
    // max-width: 82.5rem;
    // margin: 0 auto;
    // max-width: 93.75rem;
    // margin: 0 auto;
    // display: flex;
    // align-items: center;
    // justify-content: space-around;
    padding-top: 6.25rem;
    .cocntent {
      display: flex;
      justify-content: space-around;
      gap: 9.375rem;
      .footerLeft {
        display: flex;
        // justify-content: space-between;
        gap: 2.5rem;
        flex-direction: column;
        width: 30%;
        // .footerLeftTop {
        // }
        .footerLeftCenter {
          .footerLeftCenterone {
            .text {
              font-family: Archivo, Archivo;
              font-weight: bold;
              font-size: 1.25rem;
              color: #ccced3;
              font-style: normal;
              text-transform: none;
            }
            .imgs {
              display: flex;
            }
          }
        }
      }
      .footerRight {
        // width: 70%;
        display: flex;
        flex-wrap: wrap;
        gap: 1.25rem;
        .right {
          // width: 20%;
          .rightItem {
            padding-bottom: 4rem;
            .rightItemTop {
              font-family: Archivo, Archivo;
              font-weight: bold;
              font-size: 1.25rem;
              color: rgba(255, 255, 255, 0.8);
              font-style: normal;
              text-transform: none;
            }
            .rightItemBottom {
              display: flex;
              flex-direction: column;
              gap: 1rem;
              margin-top: 1.25rem;
              div {
                // font-family: PingFang SC;.
                // font-weight: 300;
                font-size: 1.125rem;
                color: #bcc4cd;
                line-height: 1.25rem;
                opacity: 0.5;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
  .line {
    max-width: 121.5625rem;
    margin: 0 auto;
    height: 0.0625rem;
    background-color: #ffffff;
    opacity: 0.2;
    margin-bottom: 1.875rem;
  }
  .footerText {
    font-family: PingFang SC;
    font-weight: 300;
    font-size: 1.125rem;
    color: #bcc4cd;
    max-width: 121.5625rem;
    margin: 0 auto;
    padding: 0.625rem;
  }
}
</style>
    