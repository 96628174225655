import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
import './utils/rem'
import 'normalize.css/normalize.css'
import { getImageUrl } from './utils/images.js'
// import 'lib-flexible'
// import HeaderIndex from "./components/header.vue";
import './assets/styles/global.scss'
import HeaderIndexOne from "./components/headerOne.vue";
import DrawerIndex from "./components/drawer.vue";
import NavitorIndex from './components/navitor.vue'
import FooterIndex from './components/footerIndex.vue'
import NavBar from './components/NavBar.vue'
import FootNav from './components/footNav.vue'
import i18n from './i18n'
Vue.use(ElementUI)
// Vue.component('HeaderIndex', HeaderIndex);  
Vue.component('HeaderIndexOne', HeaderIndexOne);
Vue.component('DrawerIndex', DrawerIndex);
Vue.component('NavitorIndex', NavitorIndex);
Vue.component('FooterIndex', FooterIndex);
Vue.component('NavBar', NavBar);
Vue.component('FootNav', FootNav);
Vue.prototype.$getImageUrl = getImageUrl
Vue.config.productionTip = true
Vue.config.warnHandler = () => null;

async function init() {
  await store.dispatch('device/initDeviceFingerprint')
  new Vue({
    store,
    router,
    i18n,
    render: h => h(App)
  }).$mount('#app')
}

init()
